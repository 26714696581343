*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: #0004;
    scroll-behavior: smooth;
    transition: all 0.2s ease !important;
}

a{
    color: inherit;
    text-decoration: none;
}

$primary-color: #aa13a6;
$secondary-color: rgba(32,58,237,1);
$tertiary-color: gold;
$success-color: #30ce73;
$success-color2: #27ae60;
$warning-color: gold;
$error-color: #ff0000;
$error-color2: #fd5959;
$light-color: #fff;
$blue: #45b1d8;
$dark-color: rgb(34, 5, 56);
$black-color: #000;
$white-color: #fff;
$transition: all 0.3s ease;
$max-width: 1300px;
$mobile: 600px;
$tablet: 1000px;
$tablet-mobile: 800px;
$border-radius: 25px;
$box-shadow: 0px 0px 10px rgba($dark-color, 0.3);
$padding-page: 5vw;
$padding-sides: 15px;
$padding-vert: 10px;
$gap-items: 7px;
$padding-section: 100px;
$padding-section-smaller: 70px;
$linear-gradient-i: linear-gradient(157deg,rgba(32,58,237,1) 0%,rgba(170,19,166,1) 26%,rgba(47,7,78,1) 100%);

.btn{
    padding: $padding-vert $padding-sides;
    background-color: #fff3;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $gap-items;
    font-size: 19px;
    font-weight: 600;
    cursor: pointer;

    @media screen and (max-width: $mobile) {
        font-size: 16px;
    }

    &.greenbutton{
        background-image: linear-gradient(to bottom,$success-color 0%,$success-color2 100%);
    }

    &.redbutton{
        background-image: linear-gradient(to bottom,$error-color2 0%,$error-color 100%);
    }

    &.outline{

    }

    &.plain{

    }
}

.hover{
    transition: $transition;
    &:hover{
        transform: scale(1.05);

        &.gap-items{
            gap: calc($gap-items * 2);
        }

        &.padding-sides{
            padding-left: calc($padding-sides + ($padding-sides/0.5));
            padding-right: calc($padding-sides + ($padding-sides/0.5));
        }

        &.invert-element{
            filter: invert(10);
        }

        &.gray-element{
            filter: grayscale(10);
        }
    }
}

.app{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: $white-color;
}

.component{
    width: 100%;
    position: relative;
    &.verify--age{
        transition: all 0.5s ease;
        // min-height: 400px;
        height: 100%;
        transition: $transition;
        overflow-y: scroll;
        @media screen and (max-height: 600px) {
          justify-content: flex-start;
          padding-top: 100px !important;  
          padding-bottom: 100px !important;  
        };
        .card--container{
            width: 100%;
            overflow-y: scroll;
            max-width: $tablet-mobile;
            text-align: center;
            min-height: 500px;
            color: #fff;
            background-color: $dark-color;
            padding: 20px 30px;
            border-radius: 5px;
            gap: 20px;
            box-shadow: $box-shadow;

            h4{
                font-weight: bold;
            }

            .logo-holder{
                max-width: 252px;
                width: 100%;
                
                img{
                    min-height: 30px;
                    width: 100%;
                }
            }
        }

        .bottom-fixed{
            bottom: 20px;
            max-width: $max-width;
        }
    }


}

.hide-verify-age{
    .verify--age{
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
}

.btn-holder{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.full-h{
    height: 100%;
}

.fixed{
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
}

.full-w{
    width: 100%;
}

.page-padding{
    padding-left: $padding-page;
    padding-right: $padding-page;
}

.default-gradient{
    background-image: $linear-gradient-i;
    
    &.animated{
        background-size: 200% 200%;
      
        animation: gradientAnimation 3s ease infinite;
        @keyframes gradientAnimation {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
        }
    }
}

.center-items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.space-btwn-items{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.flex-column-reverse{
    display: flex;
    flex-direction: column-reverse;
}

.flex-row-reverse{
    display: flex;
    flex-direction: row-reverse;
}

::-webkit-scrollbar{
    width: 6px;
}

.grayscale{
    filter: saturate(10);
}

.bottom-fixed{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: $padding-page;
    padding-right: $padding-page;
}

.link-btn{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    cursor: pointer;
    
    svg{
        font-size: 20px;
    }

    &.white-color{
        color: #fff;
    }

    &.blue{
        background-color: rgb(39, 146, 233);
    }
}

.header{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: rgba($black-color, 0.75);
    // backdrop-filter: ;
    backdrop-filter: blur(10px);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #fff4;
    overflow: hidden;

    
    @media screen and (max-width: 700px) {
        border: 1px solid #fff2;
        width: 95%;
        top: 10px;
        border-radius: 15px;
    }

    
    .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        max-width: 1600px;

        .top{
            display: flex;
            padding: 10px 50px;
            max-width: 1500px;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 30px;
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 700px) {
                padding: 10px;
            }
            &::before{
                content: "";
                transition: all 0.5s ease;
                position: absolute;
                width: 70px;
                height: 500px;
                background-color: #fff1;
                filter: blur(20px);
                transform: rotate(45deg);
                animation: move-through 6s ease forwards infinite;
            }

            @keyframes move-through {
                0%{
                    opacity: 0;
                    left: 0;
                }
                20%{
                    opacity: 0.3;
                }
                50%{
                    opacity: 1;
                }
                80%{
                    opacity: 0.3;
                }
                100%{
                    opacity: 0;
                    left: 100%;
                }
            }
            .logo-el{
                width: 120px;
                height: 40px;
                background-image: url(../assets/logo.png);
                background-size: cover;
            }
        }

        .mid{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 50px;
            flex-wrap: wrap;

            .links-holder{
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;

                @media screen and (max-width: 970px) {
                    display: none;
                }

                &:nth-child(2){
                    @media screen and (max-width: 1400px) {
                        display: none;
                    }
                }

                &:nth-child(1){
                    @media screen and (max-width: 1000px) {
                        a{
                            font-size: 15px;
                            width: max-content;
                        }
                    }
                }

                a{
                    font-weight: 700;
                    font-size: 18px;
                    padding: 5px 10px;
                    transition: all 0.5s ease;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    border-radius: 100px;
                    text-transform: capitalize;

                    &.active{
                        background-color: #fff1;
                        border: 2px solid #fff1;
                    }
                    
                    &.big{
                        background-color: #fff1;
                        border: 2px solid #fff1;
                        padding: 10px 15px;
                    }
                }
            
                
            }

            .hamburger{
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                font-size: 20px;
                border-radius: 10px;
                transition: $transition;
                border: 2px solid #fff0;
                display: none;
                position: relative;

                @media screen and (max-width: 1400px){
                    display: flex;
                }

                &:hover, &:focus, &.show-menu{
                    background-color: #fff1;
                    border: 2px solid #fff1;
                }

                &.show-menu{
                    .menu{
                        transform: translateY(0);
                        opacity: 1;
                        pointer-events: all;
                    }
                }
                

                
            }
        }

        .menu{
            transform: translateY(50%);
            pointer-events: none;
            opacity: 0;
            transition: $transition;
            &.show-menu{
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
            }
        }
        
        .menu{
            display: flex;
            position: absolute;
            top: calc(100% + 10px);
            right: 50px;
            padding: 15px 10px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            background-color: rgba($black-color, 0.6);
            border: 1px solid #fff6;
            border-radius: 10px;
            overflow: hidden;
            backdrop-filter: blur(10px);

            @media screen and (max-width: 700px) {
                right: 0;
            }
            
            &::before{
                backdrop-filter: blur(10px);
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
            }

            @media screen and (max-width: 700px) {
                position: fixed;
                width: 100%;
                display: none;
            }
            
            a{
                min-width: 150px;
                width: 100%;
                border-radius: 10px;
                // background-color: #fff;
                color: #fff;
                justify-content: flex-start;
                padding: 5px 10px;
                &.active{
                    background-color: $primary-color;
                    color: $white-color;
                }
                &:hover{
                    transform: scale(1.05);
                }
            }
        }

        .bottom{
            width: 100%;
            display: none;
            justify-content: flex-start;
            align-items: stretch;
            gap: 5px;
            padding: 0 5vw;
            padding-bottom: 20px;
            max-width: 300px;

            .btn{
                background-color: $primary-color;
                color: #fff;
                border-radius: 5px;
                border: 2px solid #fff1;
                display: flex;
                justify-content: center;
                align-items: center;

                span{
                    &.green{
                        background-color: $success-color;
                        height: 10px;
                        width: 10px;
                        border-radius: 1000px;
                    }
                }
            }

            .close{
                min-width: 40px;
                font-size: 18px;
                background-color: #fff;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                border-radius: 5px;
            }
        }

        .floating{
            position: fixed;
        }
    
    }
}

.remove-padding{
    .page{
        & > .container{
            padding-top: 100px;
        }
    }
}

.page{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $white-color;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & > .container{
        width: 100%;
        max-width: $max-width;
        padding: 0 5vw;
        padding-top: 100px;
        transition: $transition;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    section{
        width: 100%;
        position: relative;

        &.intro--section{
            width: 100vw;
            min-height: 700px;
            background-color: #0001;
            background-image: linear-gradient(#160e18d5, #160e18d5), url(../assets/Lady3.jpg);
            background-size: cover;
            display: flex;
            color: #fff;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            margin-top: -100px;
            margin-bottom: 40px;
            position: relative;
            background-attachment: fixed;
            text-align: center;
            padding: 250px 5vw;
            max-height: 100vh;

            @media screen and (max-width: 800px) {
                height: 100vh;
            }

            .auth{
                position: absolute;
                top: 120px;
                right: 5vw;
                padding: 20px;
                background-color: #fff1;
                border: 2px solid #fff1;
                border-radius: 15px;
                backdrop-filter: blur(10px);
                font-weight: 300;
                font-size: 14px;
                width: max-content;
                min-width: max-content;
                
                p{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    max-width: 100%;
                }

                a{
                    font-size: 16px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
    
                    svg{
                        font-size: 18px;
                    }
                }
            }

            h1{
                font-size: 90px;
                animation: title-in 1.5s ease forwards;
                position: relative;
                z-index: 10;

                @keyframes title-in{
                    0%{
                        filter: blur(10px);
                        opacity: 0;
                    }
                    100%{
                        filter: blur(0px);
                        opacity: 1;
                    }
                }

                @media screen and (max-width: 1200px) {
                    font-size: 70px;
                }

                @media screen and (max-width: 800px) {
                    font-size: 60px;
                }

                @media screen and (max-width: 500px) {
                    font-size: 50px;
                }
            }

            p{
                font-size: 18px;
                text-align: center;
                max-width: 70%;

                &.small{
                    font-weight: 300;
                    color: #fff9;
                }
            }

            .btn{
                margin-top: 20px;
                color: #fff;
                border: 2px solid #fff;
                background-color: transparent;
            }

            @media screen and (min-width: 800px) {
                margin-top: -100px;
            }
        }

        .fixed-el{
            &:first-child{
                right: 0;
                top: 15px !important;
                transform: rotate(15deg);
                width: 50px;
            }

            &:nth-child(2){
                right: 100px;
                top: 25px !important;
                width: 50px;
                display: none;
            }
        }
    }

    .head-section{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 40px;
        border-bottom: 2px solid #0001;
        margin-bottom: 50px;
        z-index: 20;
        position: relative;

        .headsec{
            opacity: 0;
            pointer-events: none;
            width: 100%;
            height: 100px;
            position: absolute;
            top: -100px;
        }

        .holder{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            @media screen and (max-width: 700px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }

            label{
                font-weight: 600;
            }
        }

        h2{
            margin-bottom: 10px;
        }

        .inp-holder{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
    
    background-color: $white-color;
    h2{
        font-size: 50px;
        margin-bottom: 30px;
        color: $primary-color;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;

        @media screen and (max-width: 1000px) {
            font-size: 40px;
        }

        @media screen and (max-width: 600px) {
            font-size: 30px;
        }

        span{
            background-image: linear-gradient(rgb(242, 46, 183), rgb(159, 49, 142));
            color: #fff;
            padding: 10px 20px;
            border-radius: $border-radius;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
            min-width: max-content;

        }

        @media screen and (max-width: 500px)  {
            font-size: 30px;
            
            span{
                font-size: 15px;
            }
        }
    }

    .cards-holder{
        margin-bottom: 100px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        
        @media screen and (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }
        
        @media screen and (max-width: 450px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            row-gap: 20px;
        }

        @media screen and (max-width: 300px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        .escort--card{
            width: 100%;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
            box-shadow: 4px 4px 10px #0001;
            border: 1px solid #0001;
            padding: 10px;
            border-radius: 10px;
            transition: $transition, translate 1s ease;
            position: relative;

            &:hover{
                transform: scale(1.05);
                translate: 0 -10px;
                z-index: 10;
            }

            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 23;
                pointer-events: none;
                top: 0;
                left: 0;
                transition: all 0.3s ease;
                opacity: 0;
                background-image: linear-gradient(#0000, #0000, #0000, #000);
            }

            &:hover{
                &::after{
                    opacity: 1;
                }
            }

            .bio{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 20;
                background-color: #0009;
                color: #fff;
                display: flex;
                flex-direction: column;
                gap: 10px;
                text-align: center;
                padding: 20px 20px;
                padding-bottom: 70px;
                overflow: hidden;
                height: 100%;
                overflow-y: scroll;
                transition: $transition;
                opacity: 0;
                backdrop-filter: blur(1px);
                
                h3, p{
                    transition: $transition;
                    transform: translateY(50px);
                    opacity: 0;
                    user-select: none;
                    pointer-events: none;
                }

            }

            &:hover{
                .bio{
                    opacity: 1;

                    h3, p{
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
            
            .btn{
                color: #fff;
                border-radius: 10px;
                width: 100%;
                position: relative;
                z-index: 30;
            }

            .vip{
                position: absolute;
                z-index: 10;
                background-color: red;
                color: $white-color;
                padding: 5px 10px;
                text-transform: uppercase;
                font-size: 18px;
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                border-radius: 5px;
                font-weight: 600;
                left: 5px;
                top: 5px;

                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
            
            .premium{
                @media screen and (max-width: 500px) {
                    font-size: 12px;
                    top: 20px;
                    right: -60px;
                }

                position: absolute;
                z-index: 10;
                padding: 5px 10px;
                width: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                color: #fff;
                font-weight: 600;
                text-transform: uppercase;
                background-image: linear-gradient(rgb(255, 140, 0), rgb(255, 55, 0));
                transform: rotate(45deg);
                top: 30px;
                right: -50px;
            }
            
            .top{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                position: relative;
                width: 100%;
                overflow: hidden;
                border-radius: 5px;
                background-image: linear-gradient(#0002, #0002), url(../assets/logo.png);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: #0004;
                    background-image: linear-gradient(#0000, #0004);
                    z-index: 3;
                }

                &::after{
                    content: "";
                    position: absolute;
                    width: 70px;
                    height: 140%;
                    background-color: #fffc;
                    transform: rotate(15deg);
                    filter: blur(20px);
                    animation: side-side 3s ease forwards infinite;
                }

                @keyframes side-side {
                    0%{
                        left: -50px;
                        opacity: 0;
                    }
                    50%{
                        opacity: 1;
                    }
                    100%{
                        left: calc(100% + 50px);
                    }
                }

                img{
                    // position: absolute;
                    height: 100%;
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    object-position: top;
                    top: 0;
                    left: 0;
                    position: relative;
                    z-index: 2;

                    @media screen and (max-width: 500px) {
                        height: 200px;
                    }
                }
                
                .details{
                    position: absolute;
                    bottom: 30px;
                    color: #fff;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;
                    font-size: 14px;
                    z-index: 3;

                    p{
                        font-size: 12px;
                        padding: 0 10px;
                    }


                    h3{
                        font-size: 18px;
                        
                        @media screen and (max-wdith: 600px) {
                            font-size: 15px !important;
                        }                        
                    }
                }
            }
        }
    }
    
    &.home--page{
    }

    &.register--page{
        & > .container{
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(2, 1fr);
            max-width: 900px;

            &.centered{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

            section{
                .btn{
                    margin-top: 20px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 7px;
                }
                section{
                    border: 2px solid #0001;
                }

                padding: 20px;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                align-items: center;
                width: 100%;
                h2{
                    line-height: 110%;
                    font-size: 35px;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 15px;
                
                    @media screen and (max-width: 700px) {
                        font-size: 30px;
                    }
                }

                .heading{
                    h3{
                        background-color: $primary-color;
                        color: #fff;
                        padding: 5px 15px;
                        width: 100%;
                        font-size: 18px;
                        width: 100%;
                        border-radius: 10px;
                        margin-bottom: 10px;
                    }
                }

                .features{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;
                    flex-direction: column;

                    .feature{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 6px;
                        font-size: 14px;
                        color: #666;

                        svg{
                            font-size: 18px;
                            color: $blue;
                        }
                    }
                }

                .more-info{
                    font-size: 24px;
                    margin-top: 20px;
                    margin-bottom: -10px;
                }
            }
        }

        form{
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            max-width: 500px;

            .inp-holder{
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;

                label{
                    font-weight: 600;
                }

                input, textarea{
                    background-color: rgba($primary-color, 0.1);
                    border: none;
                    padding: 10px  15px;
                    border-left: 0px solid $primary-color;
                    transition: all 0.5s ease;
                    outline: none;
                    border: 1px solid #0002;
                    border-radius: 5px;

                    &:focus, &:hover{
                        border-left: 3px solid $primary-color;
                    }
                }

                textarea{
                    min-width: 100%;
                    max-width: 100%;
                    height: 100px;
                    min-height: 70px;
                    max-height: 500px;
                }

                .lang-selector{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin-top: 10px;

                    .lang{
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                    }
                }

                .rates-holder{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 10px;
                    margin-bottom: 30px;

                    .tab{
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        row-gap: 10px;
                        column-gap: 10px;

                        p, .time{
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #555;
                            font-weight: 600;
                        }

                        p{
                            border: 2px solid;
                        }
                    }
                }

                .selector{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;

                    &.services{
                        margin-bottom: 30px;
                    }

                    .select{
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        font-size: 14px;
                        padding: 5px;
                        color: #555;
                        border: 1px solid #0002;
                        border-radius: 5px;
                        cursor: pointer;
                        &.active{
                            background-color: #0001;
                            border: 1px solid #0002;
                        }
                        &:first-child{
                            svg{
                                color: green;
                            }
                        }
                        
                        &:last-child{
                            svg{
                                color: $primary-color;
                            }
                        }
                        
                        p{
                            font-size: 14px;
                            color: #444;
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            svg{
                                font-size: 16px;
                            }

                        }
                    }
                }
            }
        }
    }
}

.rated-bg{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.fixed-el{
    position: absolute;
    width: 70px;
    z-index: 0;
    opacity: 0.1;
    top: 0px;
    right: 0px;
    pointer-events: none;

    img{
        width: 100%;
    }
}

.scroll-to-top{
    position: fixed;
    z-index: 50;
    width: 50px;
    height: 50px;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    right: 30px;
    color: #fff;
    background-color: #0009;
    backdrop-filter: blur(10px);
    font-size: 20px;
    transition: $transition;
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
    translate: 0 100px;
    transition: all 0.5s ease, translate 1s ease;
    cursor: pointer;

    &.show{
        transform: scale(1);
        translate: 0 0px;
        opacity: 1;
        pointer-events: all;
    }
}

.selector{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    margin-bottom: 20px;
    h3{
        margin-right: 20px;
    }

    span{
        padding: 5px 10px;
        border-radius: 10px;
        background-color: rgba($primary-color, 0.1);
        border: 2px solid rgba($primary-color, 0.1);
        cursor: pointer;
        transition: all 0.5s ease;
        
        &.active{
            background-color: rgba($primary-color, 0.9);
            color: #fff;
            padding: 5px 15px;
        }
    }
}

footer{
    display: flex;
    justify-content: center;
    align-items: center;

    .max-width{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin-top: 40px;
        color: #333;
    }
}

a{
    transition: $transition;
}

.page{
    animation: page-in 1s ease forwards;
}

@keyframes page-in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

button{
    outline: none;
    border: none;
}

a{
    text-transform: capitalize;
}

.roles-holder{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    flex-direction: row !important;

    span{
        width: fit-content !important;
        max-width: fit-content !important;
        padding: 3px 10px;
        cursor: pointer;
        background-color: rgba($primary-color, 0.1);
        color: #000;
        border: 1px solid rgba($primary-color, 0.1) !important;
        transition: $transition;
        border-radius: 10px;

        &.active{
            background-color: $primary-color !important;
            color: #fff !important;
        }
    }
}

form{
    animation: scale-in 1s ease forwards;
}

@keyframes scale-in {
    0%{
        // transform: scale(0);
        translate: 0 50px;
        opacity: 0;
    }
}

.preloader{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000c;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    span{
        @media screen and (max-width: 800px) {
            width: 60px !important;
            height: 60px !important;
        }
    }
}

.profile--page{
    .container{
        gap: 30px;
    }
    .profile--section{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin-top: 70px;

        .img-holder{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 200px;
            position: relative;
            background-color: #0009;
            color: #fff;
            border-radius: 1000px;
            font-size: 100px;

            &::before{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                transform: scale(1.05);
                border: 2px solid #ccc;
                display: flex;
                border-radius: inherit;
            }

            svg{
                position: absolute;
            }

            img{
                border-radius: inherit;
                width: 100% !important;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .gallery-img-holder{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
            padding-bottom: 50px;
            border-bottom: 2px solid #0001;
            width: fit-content;
            max-width: 100%;



            .gallery-img{
                width: 200px;
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                overflow: hidden;
                box-shadow: 0px 0px 10px #0001;
                background-color: #0008;
                transition: $transition, translate 0.7s ease ;
                color: #fff;
                font-size: 30px;
                position: relative;
                p{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 2;
                    background-color: #0004;
                    width: 39px;
                    height: 39px;
                    border-radius: inherit;
                    backdrop-filter: blur(10px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 17px;
                    cursor: pointer;
                    transition: $transition;
                    box-shadow: 0px 0px 10px #0003 inset;
                    animation: scale-inx 2s ease forwards;

                    &:hover{
                        color: red;
                    }

                    @keyframes scale-inx{
                        0%{
                            transform: scale(0);
                            opacity: 0;
                        }
                        100%{
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                }
                svg{
                    transition: $transition;
                }

                &:hover{
                    transform: scale(1.02);
                    z-index: 3;
                    translate: 0 -10px;

                    svg{
                        transform: scale(0.9);
                    }
                }

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            @media screen and (max-width: 500px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;

                .gallery-img{
                    width: 100%;
                }
            }

            @media screen and (max-width: 300px) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                width: 100%;

                .gallery-img{
                    width: 100%;
                }
            }
        }

        h3{
            margin-bottom: 10px;
            font-size: 24px;
        }

        .detail{
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            label{
                font-weight: 600;
            }

            span, input{
                border: none;
                background-color: rgba($primary-color, 0.1);
                color: $black-color;
                border-left: 0px solid $primary-color;
                transition: $transition;
                padding: 10px 15px;
                width: 700px;
                max-width: 100%;
                min-width: 100px;

                &:hover{
                    border-left: 5px solid $primary-color;
                }
            }
        }
    }

    .head-section{
        display: flex;
        flex-direction: column;
        gap: 10px;

        section{
            border: 2px solid #0001;
        }

        .profile-actions{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .completion{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                .big{
                    font-weight: 700;
                    font-size: 30px;
                    margin-left: 10px;
                }

                .detail{
                    width: 100%;
                    max-width: 300px;
                    height: 4px;
                    background-color: #0002;
                    overflow: hidden;
                    border-radius: 100px;

                    p{
                        height: 4px;
                        min-width: 2% !important;
                        background-color: $primary-color;
                    }
                }
            }

            .info{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                margin-top: 20px;

                .btn{
                    padding: 10px 15px;
                    font-size: 14px;
                    border: none;
                    background-color: $primary-color;
                    color: $white-color;
                    border-radius: 1000px;
                    transition: $transition;
                    opacity: 0.2;
                    cursor: not-allowed;
                    
                    &.active{
                        opacity: 1;
                        cursor: pointer;
                    }

                    &:hover{
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 500px;

        .inp-holder{
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            label{
                font-weight: 600;
            }

            input, textarea{
                background-color: rgba($primary-color, 0.1);
                border: none;
                padding: 10px  15px;
                border-left: 0px solid $primary-color;
                transition: all 0.5s ease;
                outline: none;
                border: 1px solid #0002;
                border-radius: 5px;

                &:focus, &:hover{
                    border-left: 3px solid $primary-color;
                }
            }

            textarea{
                min-width: 100%;
                max-width: 100%;
                height: 100px;
                min-height: 70px;
                max-height: 500px;
            }

            .lang-selector{
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin-top: 10px;

                .lang{
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                }
            }

            
        }
    }

    input, textarea{
        background-color: rgba($primary-color, 0.1);
        border: none;
        padding: 10px  15px;
        border-left: 0px solid $primary-color;
        transition: all 0.5s ease;
        outline: none;
        border: 1px solid #0002;
        border-radius: 5px;

        &:focus, &:hover{
            border-left: 3px solid $primary-color;
        }
    }

    textarea{
        min-width: 100%;
        max-width: 100%;
        height: 100px;
        min-height: 70px;
        max-height: 500px;
    }

    .rates-holder{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        margin-bottom: 30px;

        .tab{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 10px;
            column-gap: 10px;

            p, .time{
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #555;
                font-weight: 600;
            }

            p{
                border: 2px solid;
            }
        }
    }

    .selector{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        &.services{
            margin-bottom: 30px;
        }

        .select{
            display: flex;
            align-items: center;
            gap: 7px;
            font-size: 14px;
            padding: 5px;
            color: #555;
            border: 1px solid #0002;
            border-radius: 5px;
            cursor: pointer;
            &.active{
                background-color: #0001;
                border: 1px solid #0002;
            }
            &:first-child{
                svg{
                    color: green;
                }
            }
            
            &:last-child{
                svg{
                    color: $primary-color;
                }
            }
            
            p{
                font-size: 14px;
                color: #444;
                display: flex;
                align-items: center;
                gap: 4px;

                svg{
                    font-size: 16px;
                }

            }
        }
    }
    .btn-holder{
        width: 700px;
        display: flex;
        align-items: center;
        margin-top: 30px;
        padding-bottom: 40px;
        border-bottom: 2px solid #0001;
        max-width: 100%;
        .btn{
            background-color: $primary-color;
            color: $white-color;
            padding: 10px 15px;
            border-radius: 1000px;
        }
    }
}

form.container{
    animation: none;
}

.location-holder{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: -10px;

    label{
        font-weight: 600;
    }
}

.info.xyz{
    display: flex;
    flex-wrap: wrap;

    button{
        width: max-content;

        &:nth-child(3){
            display: none;
        }

        &:nth-child(4){
            display: none;
        }
    }
}

::-webkit-scrollbar{
    width: 0px;
}

.services-holder{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    margin-top: -10px;

    .service{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding: 10px;

        svg{
            color: $success-color;
        }

        p{
            font-weight: 600;
        }

        .del-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background-color: #f002;
            border-radius: 5px;
            margin-left: 10px;
            cursor: pointer;
            transition: all 0.5s ease;
            &:hover{
                background-color: red;
                color: #fff;
                
                svg{
                    color: #fff;
                    transform: rotate(360deg);
                }
            }

            svg{
                color: #f00;
                transition: all 0.5s ease;
            }
        }
    }

}

.add-service{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;

    input{
        padding: 10px 15px;
        background-color: #0001;
        border: none;
        border-radius: 10px;
    }

    .add-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 20px;
        background-color: #000;
        color: #fff;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
    }
}

.services-min{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

    .service-min{
        background-color: #fff2;
        padding: 5px 10px;
        font-weight: 600;
        border-radius: 10px;
    }
}

.btn{
    &.success{
        background-image: linear-gradient($success-color, $success-color2);
        color: $white-color;
    }
}

.menux{
    pointer-events: none;
    opacity: 0;
    transition: $transition;
    &.show-menu{
        opacity: 1;
        pointer-events: all;
        transform: scale(1);
    }
}

.menux{
    display: none;
    position: fixed;
    z-index: 9;
    top: 0;
    right: 50px;
    width: 100%;
    padding: 15px 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: rgba($black-color, 0.7);
    border: 1px solid #fff2;
    // border-radius: 10px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    height: 100%;
    padding-top: 100px;
    gap: 10px;
    display: flex !important;
    left: 0 !important;


    @media screen and (max-width: 700px) {
        right: 0;
    }
    
    &::before{
        backdrop-filter: blur(10px);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        pointer-events: none;
    }

    @media screen and (max-width: 700px) {
        position: fixed;
        width: 100%;
        display: flex;
    }
    
    a{
        min-width: 150px;
        width: 100%;
        border-radius: 10px;
        // background-color: #fff;
        color: #fff;
        justify-content: flex-start;
        padding: 10px 15px;
        border: 1px solid #fff0;
        font-weight: 500;
        color: #aaa;
        &.active{
            color: #fff;
            background-color: #fff1;
            color: $white-color;
            border: 1px solid #fff4;
            font-size: 18px;
        }
        &:hover{
            border: 1px solid #fff2;
        }
    }
}